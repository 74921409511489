import { PropertyApplicationApiBase } from './base';

export class ApplicationGuarantorApi extends PropertyApplicationApiBase {
  createGuarantor(applicationId, data) {
    return super.post(
      `${applicationId}/guarantors/`,
      data,
      `Could not add a guarantor for applicant ${data.guarantor_for}`
    );
  }
}
