import { ROUTES as FLATBOND_ROUTES } from '@/pages/agent/flatbonds';

export const ROUTES = {
  HOMEPAGE: 'HOMEPAGE',
  FLATFAIR_DEMO: 'FLATFAIR_DEMO',
  CREATE_PLAN_DEMO: 'CREATE_PLAN_DEMO',
  TT_JOURNEY_DEMO: 'TT_JOURNEY_DEMO',
  EOT_JOURNEY_DEMO: 'EOT_JOURNEY_DEMO',
  QUIZ_START: 'ONBOARDING_QUIZ_START',
  SELF_BILLING: 'SELF_BILLING',
  QUIZ: 'ONBOARDING_QUIZ',
  QUIZ_RESULTS: 'ONBOARDING_QUIZ_RESULTS',
  CREATE_PLAN_START: 'CREATE_PLAN_START',
  DEPOSIT_INFO: 'DEPOSIT_INFO',
  MATERIALS: 'MATERIALS'
};

export const homepage = {
  name: ROUTES.HOMEPAGE,
  path: '/',
  component: () => import('@/components/agent/homepage/onboarding_homepage.vue')
};

export const flatfairDemo = {
  name: ROUTES.FLATFAIR_DEMO,
  path: '/agent/onboarding/video/flatfair-demo',
  component: () => import('@/components/agent/homepage/flatfair_demo.vue'),
  meta: {
    PREV_STEP: ROUTES.HOMEPAGE
  }
};

export const howToCreatePlanDemo = {
  name: ROUTES.CREATE_PLAN_DEMO,
  path: '/agent/onboarding/video/plan-demo',
  component: () => import('@/components/agent/homepage/create_plan_demo.vue'),
  meta: {
    PREV_STEP: ROUTES.HOMEPAGE
  }
};

export const tenantJourneyDemo = {
  name: ROUTES.TT_JOURNEY_DEMO,
  path: '/agent/onboarding/video/tenant-journey-demo',
  component: () => import('@/components/agent/homepage/tenant_journey_demo.vue'),
  meta: {
    PREV_STEP: ROUTES.HOMEPAGE
  }
};

export const eotJourneyDemo = {
  name: ROUTES.EOT_JOURNEY_DEMO,
  path: '/agent/onboarding/video/eot-journey-demo',
  component: () => import('@/components/agent/homepage/end_of_tenancy_demo.vue'),
  meta: {
    PREV_STEP: ROUTES.HOMEPAGE
  }
};

export const creatDraftPlanStart = {
  name: ROUTES.CREATE_PLAN_START,
  path: '/agent/plan',
  component: () => import('@/components/agent/homepage/create_flatbond_start.vue'),
  meta: {
    NEXT_STEP: FLATBOND_ROUTES.SELECT_TYPE
  }
};

export const depositInfo = {
  name: ROUTES.DEPOSIT_INFO,
  path: '/agent/onboarding/deposit',
  component: () => import('@/components/agent/homepage/deposit_information.vue'),
  meta: {
    PREV_STEP: ROUTES.HOMEPAGE
  }
};

export const selfBillingSetup = {
  name: ROUTES.SELF_BILLING,
  path: '/agent/onboarding/self-billing',
  component: () => import('@/components/agent/homepage/self_billing_setup.vue'),
  meta: {
    PREV_STEP: ROUTES.HOMEPAGE
  }
};

export const trainingQuizStart = {
  name: ROUTES.QUIZ_START,
  path: '/agent/onboarding/quiz/start',
  component: () => import('@/components/agent/homepage/quiz_start.vue'),
  meta: {
    PREV_STEP: ROUTES.HOMEPAGE,
    NEXT_STEP: ROUTES.QUIZ
  }
};

export const trainingQuiz = {
  name: ROUTES.QUIZ,
  path: '/agent/onboarding/quiz',
  component: () => import('@/components/agent/homepage/quiz.vue'),
  meta: {
    PREV_STEP: ROUTES.QUIZ_START,
    NEXT_STEP: ROUTES.QUIZ_RESULTS
  }
};

export const trainingQuizResults = {
  name: ROUTES.QUIZ_RESULTS,
  path: '/agent/onboarding/quiz/results',
  component: () => import('@/components/agent/homepage/quiz_results.vue'),
  meta: {
    PREV_STEP: ROUTES.QUIZ
  }
};

export const materials = {
  name: ROUTES.MATERIALS,
  path: '/agent/onboarding/materials',
  component: () => import('@/components/agent/homepage/onboarding_materials.vue')
};

export default [
  trainingQuizStart,
  creatDraftPlanStart,
  trainingQuiz,
  trainingQuizResults,
  howToCreatePlanDemo,
  tenantJourneyDemo,
  eotJourneyDemo,
  homepage,
  flatfairDemo,
  depositInfo,
  materials,
  selfBillingSetup
];
