import BaseModel from '@/models/base';
import { RENT_WEEKLY_MAX, RENT_WEEKLY_MIN } from '@/models/flatbond_draft';
import {
  REFERENCE_RECOMMENDATION_STATUSES,
  REFERENCING_PROVIDER
} from '@/libs/constants/generatedConstants';

export const STATUS_OPTIONS = {
  PENDING_CASE_CREATION: 'pending_case_creation',
  CREATING: 'creating',
  INVITED: 'invited',
  IN_PROGRESS: 'in_progress',
  PENDING_REVIEW: 'pending_review',
  COMPLETED: 'completed',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled'
};

const STATUS_LABEL_MAP = {
  [STATUS_OPTIONS.PENDING_CASE_CREATION]: 'referencing.referenceRequest.status.pendingCaseCreation',
  [STATUS_OPTIONS.CREATING]: 'referencing.referenceRequest.status.creating',
  [STATUS_OPTIONS.INVITED]: 'referencing.referenceRequest.status.invited',
  [STATUS_OPTIONS.IN_PROGRESS]: 'referencing.referenceRequest.status.inProgress',
  [STATUS_OPTIONS.PENDING_REVIEW]: 'referencing.referenceRequest.status.pendingReview',
  [STATUS_OPTIONS.COMPLETED]: 'referencing.referenceRequest.status.completed',
  [STATUS_OPTIONS.EXPIRED]: 'referencing.referenceRequest.status.expired',
  [STATUS_OPTIONS.CANCELLED]: 'referencing.referenceRequest.status.cancelled'
};

export class ReferenceRequestTenantUserModel extends BaseModel {
  defaults() {
    return {
      firstName: ''
    };
  }
}

export class ReferenceRequestApplicantModel extends BaseModel {
  defaults() {
    return {
      title: '',
      firstName: '',
      lastName: '',
      userEmail: '',
      phoneNumber: ''
    };
  }
}

export class ReferenceRequestGuarantorModel extends BaseModel {
  defaults() {
    return {
      firstName: '',
      lastName: '',
      userEmail: '',
      phoneNumber: '',
      guarantorFor: null
    };
  }
}

export class ReferenceRequestModel extends BaseModel {
  constructor(data) {
    super(data);
    this.tenantUser = new ReferenceRequestTenantUserModel(this.tenantUser);
  }

  defaults() {
    return {
      status: null,
      hasReferencingUpsellFlowEnabled: false,
      thirdPartyLink: null,
      groupName: '',
      address: '',
      rent: 0,
      applicationRent: 0,
      referencingProvider: '',
      noDepositMembershipFeePerTenant: 0,
      noDepositMembershipFeeWithVatPerTenant: 0,
      noDepositTotalMembershipFeeWithVat: 0,
      interestedInNoDeposit: false,
      hasSeenNoDepositUpsell: false,
      tenantUser: new ReferenceRequestTenantUserModel()
    };
  }

  referencingProviderName() {
    if (this.referencingProvider === REFERENCING_PROVIDER.HOMEPPL) {
      return 'Homeppl';
    } else if (this.referencingProvider === REFERENCING_PROVIDER.HOMELET) {
      return 'Homelet';
    } else if (this.referencingProvider === REFERENCING_PROVIDER.MOVEM) {
      return 'Movem';
    }
    return '';
  }

  statusLabel($t) {
    return $t(STATUS_LABEL_MAP[this.status]);
  }

  get isPending() {
    return [
      STATUS_OPTIONS.PENDING_CASE_CREATION,
      STATUS_OPTIONS.CREATING,
      STATUS_OPTIONS.INVITED,
      STATUS_OPTIONS.IN_PROGRESS,
      STATUS_OPTIONS.PENDING_REVIEW
    ].includes(this.status);
  }

  get isFailed() {
    return [STATUS_OPTIONS.CANCELLED, STATUS_OPTIONS.EXPIRED].includes(this.status);
  }

  get hasFailedReference() {
    return this.response.recommendation_status === REFERENCE_RECOMMENDATION_STATUSES.NOT_ACCEPTABLE;
  }

  get eligibleForNoDepositUpsell() {
    const passRentCheck =
      RENT_WEEKLY_MIN <= this.applicationRent && this.applicationRent <= RENT_WEEKLY_MAX;
    const maxDeposit = this.applicationRent * 5;
    const passCostCheck = this.noDepositTotalMembershipFeeWithVat < maxDeposit;

    return passRentCheck && passCostCheck;
  }
}
